import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Countdown from "../components/countdown"
import Arrow from "../components/arrow"
import style from "./index.module.scss"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay, Pagination } from "swiper/core"

SwiperCore.use([Autoplay, Pagination])

const sponsorLevel = [
  { name: "鑽石級", img: "/images/sponsor-diamond.svg" },
  { name: "白金級", img: "/images/sponsor-platinum.svg" },
  { name: "金級", img: "/images/sponsor-golden.svg" },
  { name: "銀級", img: "/images/sponsor-silver.svg" },
]

const IndexPage = ({ data }) => {
  const speakers = data.allStrapiSpeaker.edges.map(e => e.node)
  const writing = {}
  data.allStrapiWriting.edges.forEach(element => {
    writing[element.node.key] = element.node.writing
  })
  const presses = data.allStrapiPress.edges.map(e => e.node)
  const sponsor = []
  for (let i = 1; i <= 7; i++) {
    sponsor.push(
      data.allStrapiSponsor.nodes.filter(e => {
        return e.level === i
      })
    )
  }
  const speakerChunk = Array.from(
    { length: Math.ceil(speakers.length / 6) },
    (v, i) => speakers.slice(i * 6, i * 6 + 6)
  )

  return (
    <Layout>
      <SEO title="首頁" />
      <Hero />
      <div
        style={{
          maxWidth: 1300,
          margin: "0 auto",
          padding: "1em",
        }}
      >
        <Countdown></Countdown>
        <hr className="space" />
        <div className={style.caption}>
          <strong>
            <div className={style.captionContent}>
              設計下一個十年 - 多元與躍進
              <br /> Imagine the next 10 years - Diversity and Advancement
            </div>
          </strong>
          <div
            className={style.captionIntroduction}
            dangerouslySetInnerHTML={{
              __html:
                writing.introduction &&
                writing.introduction.replace(/(?:\r\n|\r|\n)/g, "<br/>"),
            }}
          ></div>
        </div>

        <div className={style.speakers}>
          <strong>
            <h2>講者</h2>
          </strong>
          <div className={style.swiperWraper}>
            <Swiper
              spaceBetween={0}
              slidesPerView={1}
              autoHeight={true}
              speed={1000}
              pagination={{ clickable: true }}
              scrollbar={{ draggable: true }}
              autoplay={{ delay: 5000 }}
            >
              {speakerChunk.map((v, j) => {
                return (
                  <SwiperSlide key={`slide${j}`} className={style.mainSpeakers}>
                    {v.map((e, i) => (
                      <Link
                        key={`speaker${i}-${j}`}
                        to={e.speakerPage}
                        state={{
                          modal: true,
                        }}
                        id={i}
                        className={style.speaker}
                      >
                        <img
                          alt={e.name.name}
                          src={e.avatar && e.avatar.childImageSharp.resize.src}
                        />
                        <h3>{e.name.name}</h3>
                        <h3>{e.name.origional_name}</h3>
                        <div>
                          {e.job && e.job.corp}
                          <br />
                          {e.job && e.job.job_title}
                        </div>
                      </Link>
                    ))}
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </div>
          <Link className={style.arrowLink} to="/speaker">
            查看全部講者
            <Arrow />
          </Link>
        </div>
      </div>
      <div className={style.schedules}>
        <h2>主要活動</h2>
        <div className={style.scheduleDays}>
          <div className={style.scheduleDay}>
            <Link to={"/agenda/2021-05-14/"} className={style.scheduleCard}>
              <h3 className={style.date}>5/14 (五)</h3>
              <h3 className={style.event}>
                <span>5</span> 場
              </h3>
              <h3 className={style.event}>工作坊</h3>
            </Link>
          </div>
          <div className={style.scheduleDay}>
            <Link to={"/agenda/2021-05-15/"} className={style.scheduleCard}>
              <h3 className={style.date}>5/15 (六)</h3>
              <h3 className={style.event}>
                <span>19</span> 個
              </h3>
              <h3 className={style.event}>議程</h3>
            </Link>
          </div>
          <div className={style.scheduleDay}>
            <Link to={"/agenda/2021-05-16/"} className={style.scheduleCard}>
              <h3 className={style.date}>5/16 (日)</h3>
              <h3 className={style.event}>
                <span>17</span> 個
              </h3>
              <h3 className={style.event}>議程</h3>
            </Link>
          </div>
        </div>
      </div>
      <div
        style={{
          maxWidth: 1310,
          margin: "0 auto",
          padding: "1em",
        }}
      >
        <div className={style.press}>
          <strong>
            <h2>報導</h2>
          </strong>
          <div className={style.list}>
            {presses.map((press, index) => {
              const title =
                press.title.length > 52
                  ? press.title.substr(0, 52) + "..."
                  : press.title
              const desc =
                press.description.length > 62
                  ? press.description.substr(0, 62) + "..."
                  : press.description
              return (
                <Link
                  className={style.item}
                  key={press.id}
                  id={index}
                  to={press.pressPage}
                >
                  <div className={style.title}>
                    <div className={style.content}>
                      <h4 className={style.limit}>{title}</h4>
                      <div className={style.limit}>{desc}</div>
                    </div>
                  </div>
                  <div className={style.image}>
                    <img
                      alt={press.title}
                      src={press.og_image.childImageSharp.resize.src}
                    />
                  </div>
                  <div className={style.author}>
                    <div>
                      <img
                        alt={press.author.name.name}
                        src={
                          press.author.avatar &&
                          press.author.avatar.childImageSharp.resize.src
                        }
                      />
                    </div>
                    <div>
                      <h4>{press.author.name.name}</h4>
                      {new Intl.DateTimeFormat("zh-TW").format(
                        new Date(press.created_at)
                      )}
                    </div>
                  </div>
                </Link>
              )
            })}
          </div>
          <Link className={style.arrowLink} to="/press/">
            查看全部報導
            <Arrow />
          </Link>
        </div>
        <div className={style.sponsors}>
          <strong>
            <h2>贊助廠商</h2>
          </strong>
          <div className={style.content}>
            {sponsorLevel.map((level, i) => {
              if (sponsor[i].length) {
                return (
                  <div
                    className={style.level}
                    key={i}
                    style={{
                      width: i === sponsorLevel.length - 1 ? "unset" : null,
                    }}
                  >
                    <h3 className={style.name}>
                      <img alt={level.name} src={level.img}></img>
                      {level.name}
                    </h3>
                    {sponsor[i].map((s, j) => (
                      <a
                        key={i + "a" + j}
                        className={style.sponsor}
                        href={s.link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          alt={s.name}
                          src={s.logo.childImageSharp.original.src}
                        />
                      </a>
                    ))}
                  </div>
                )
              } else {
                return null
              }
            })}
          </div>
        </div>
        <div className={style.callForSponsor}>
          <div className={style.content}>
            <div className={style.buttonContainer}>
              <a
                className="cta"
                href="mailto:sponsor@ixda.org.tw"
                style={{ maxWidth: 500 }}
              >
                我有興趣贊助
              </a>
            </div>
            <div
              className={style.buttonContainer}
              dangerouslySetInnerHTML={{
                __html:
                  writing.callForSponsor &&
                  writing.callForSponsor.replace(/(?:\r\n|\r|\n)/g, "<br/>"),
              }}
            ></div>
          </div>
        </div>
        {sponsor[4].length > 0 && (
          <div className={style.sponsors}>
            <strong>
              <h2>協辦單位</h2>
            </strong>

            <h3>&nbsp;</h3>
            <div className={style.content}>
              <div>
                {sponsor[4].map((s, i) => (
                  <a
                    key={i}
                    className={style.sponsor}
                    href={s.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      alt={s.name}
                      src={s.logo.childImageSharp.original.src}
                    />
                  </a>
                ))}
              </div>
            </div>
          </div>
        )}
        {sponsor[5].length > 0 && (
          <div className={style.sponsors}>
            <strong>
              <h2>協力社群</h2>
            </strong>
            <h3>&nbsp;</h3>
            <div className={style.content}>
              <div>
                {sponsor[5].map((s, i) => (
                  <a
                    key={i}
                    className={style.sponsor}
                    href={s.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      alt={s.name}
                      src={s.logo.childImageSharp.original.src}
                    />
                  </a>
                ))}
              </div>
            </div>
          </div>
        )}
        {sponsor[6].length > 0 && (
          <div className={style.sponsors}>
            <strong>
              <h2>協力媒體</h2>
            </strong>
            <h3>&nbsp;</h3>
            <div className={style.content}>
              <div>
                {sponsor[6].map((s, i) => (
                  <a
                    key={i}
                    className={style.sponsor}
                    href={s.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      alt={s.name}
                      src={s.logo.childImageSharp.original.src}
                    />
                  </a>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query homeQuery {
    allStrapiPage(filter: { site: { id: { eq: 2 } } }) {
      edges {
        node {
          id
          page: gatsbyPath(filePath: "/{StrapiPage.slug}")
        }
      }
    }
    allAgendaYaml {
      edges {
        node {
          id
          agendaPage: gatsbyPath(filePath: "/agenda/{AgendaYaml.id}")
        }
      }
    }
    allStrapiSession(
      filter: {
        session_type: { elemMatch: { id: { eq: 1 } } }
        event: { id: { eq: 1 } }
      }
    ) {
      edges {
        node {
          start_time
          end_time
          name
        }
      }
    }
    allStrapiSpeaker(
      filter: { site: { id: { eq: 2 } } }
      sort: { order: ASC, fields: carousel }
    ) {
      edges {
        node {
          speakerPage: gatsbyPath(filePath: "/speaker/{StrapiSpeaker.slug}")
          job {
            corp
            job_title
          }
          name {
            id
            name
            origional_name
          }
          slug
          avatar {
            childImageSharp {
              resize(width: 320, height: 320) {
                src
                width
                height
                aspectRatio
              }
            }
          }
        }
      }
    }
    allStrapiWriting(filter: { site: { id: { eq: 2 } } }) {
      edges {
        node {
          key
          writing
          site {
            id
          }
        }
      }
    }
    allStrapiPress(
      sort: { order: DESC, fields: strapiId }
      filter: { site: { id: { eq: 2 } } }
      limit: 3
    ) {
      edges {
        node {
          id
          slug
          pressPage: gatsbyPath(filePath: "/press/{StrapiPress.slug}")
          title
          description
          content
          author {
            avatar {
              childImageSharp {
                resize(width: 320, height: 320) {
                  src
                  width
                  height
                  aspectRatio
                }
              }
            }
            name {
              name
              origional_name
            }
          }
          og_image {
            childImageSharp {
              resize(width: 720, height: 540, cropFocus: CENTER) {
                src
              }
            }
          }
          created_at
        }
      }
    }
    allStrapiSponsor(filter: { site: { id: { eq: 2 } } }) {
      nodes {
        link
        level
        logo {
          childImageSharp {
            original {
              src
            }
          }
          name
        }
      }
    }
  }
`
