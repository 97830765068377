import React from "react"
import style from "./countdown.module.scss"

const Num = props => (
  <span className={style.mono}>
    {props.value <= 9 && "0"}
    {props.value}
  </span>
)

class Countdown extends React.Component {
  constructor(props) {
    super(props)
    // const pre = props.sessions.map(e => {
    //   return {
    //     text: `距離下一場議程開始時間還有`,
    //     time: new Date(e.start_time),
    //     end_time: new Date(e.end_time),
    //   }
    // })

    // this.times = pre
    // this.times.sort((a, b) => a.time.getTime() - b.time.getTime())
    // const now = new Date()

    // this.times[0].text = "距離大會開幕開始時間還有"

    // this.times.push({
    //   text: `距離大會結束時間還有`,
    //   time: this.times[this.times.length - 1].end_time,
    //   start: 1,
    // })

    // this.current = -1
    // for (let i = this.times.length - 1; i >= 0; i--) {
    //   const t = this.times[i]
    //   if (now.getTime() < t.time.getTime()) {
    //     this.current = i
    //   }
    // }

    this.state = {
      text: "",
      month: "",
      day: "",
      hour: "",
      minute: "",
      second: "",
    }
  }

  componentDidMount() {
    this.interval = window.setInterval(() => this.update(), 1000)
  }

  componentWillUnmount() {
    window.clearInterval(this.interval)
  }

  update() {
    const now = new Date()
    let text,
      day,
      hour,
      minute,
      second = ""
    let t1, t2

    // if (this.current >= this.times.length || this.current === -1) {
      text = "大會已經結束了"
      t1 = new Date(2022,0,1,0,0,0)
      t2 = now
    // } else {
    //   if (this.times[this.current].time.getTime() < now.getTime()) {
    //     this.current += 1
    //   }
    //   text = this.times[this.current].text
    //   t1 = now
    //   t2 = this.times[this.current].time
    // }

    second = t2.getSeconds() - t1.getSeconds()
    minute = t2.getMinutes() - t1.getMinutes()
    hour = t2.getHours() - t1.getHours()
    day = Math.floor((t2.getTime() - t1.getTime()) / 1000 / 86400)

    if (second < 0) {
      second += 60
      minute -= 1
    }
    if (minute < 0) {
      minute += 60
      hour -= 1
    }
    if (hour < 0) {
      hour += 24
      day -= 1
    }

    this.setState({
      text,
      day,
      hour,
      minute,
      second,
    })
  }

  render() {
    const { text, day, hour, minute, second } = this.state
    return (
      <>
        <h3 className={style.title}>{text}</h3>
        <div className={style.counter}>
          {day !== 0 && (
            <>
              <Num value={day} />日
            </>
          )}
          <Num value={hour} />
          時
          <Num value={minute} />
          分
          <Num value={second} />秒
        </div>
      </>
    )
  }
}

export default Countdown
