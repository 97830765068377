import React from "react"

const Arrow = () => (
  <svg
    width="28px"
    height="27px"
    viewBox="0 0 28 27"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="home_mobile" transform="translate(-173.000000, -2353.000000)">
        <g id="Group-9" transform="translate(25.000000, 1448.000000)">
          <g id="content_next" transform="translate(147.000000, 903.441970)">
            <rect
              id="Rectangle"
              fill="#FF9292"
              transform="translate(15.000000, 15.000000) rotate(45.000000) translate(-15.000000, -15.000000) "
              x="5"
              y="5"
              width="20"
              height="20"
              rx="2"
            ></rect>
            <g
              id="Group-19"
              transform="translate(9.000000, 9.000000)"
              fill="#FFFFFF"
            >
              <path
                d="M8.8,3.06666667 L12.8,8.4 C13.1313708,8.8418278 13.0418278,9.46862915 12.6,9.8 C12.4269038,9.92982213 12.2163702,10 12,10 L4,10 C3.44771525,10 3,9.55228475 3,9 C3,8.78362979 3.07017787,8.57309617 3.2,8.4 L7.2,3.06666667 C7.53137085,2.62483887 8.1581722,2.53529582 8.6,2.86666667 C8.67580567,2.92352092 8.74314575,2.990861 8.8,3.06666667 Z"
                id="Triangle"
                transform="translate(8.000000, 6.000000) rotate(90.000000) translate(-8.000000, -6.000000) "
              ></path>
              <rect
                id="Rectangle"
                x="0"
                y="4"
                width="7"
                height="4"
                rx="1"
              ></rect>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Arrow
