import React from "react"
import style from "./hero.module.scss"

const Hero = () => (
  <>
    <div className={style.hero}>
      <picture>
        <source
          media="(orientation: portrait)"
          srcSet="/images/hero.portrait.png"
        />
        <source
          media="(orientation: landscape)"
          srcSet="/images/hero.landscape.svg"
        />
        <img
          className={style.image}
          src="/images/hero.landscape.svg"
          alt="設計下一個十年 - 多元與躍進 Imagine the next 10 years - Diversity and Advancement 2021 年 5 月 14、15、16 日 張榮發基金會"
        />
      </picture>
    </div>
  </>
)

export default Hero
